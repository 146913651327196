<template>
  <div class="login">
    <b-jumbotron header="Passwort ändern">
      <b-alert v-if="$store.state.pw_must_change" show variant="danger">Bitte ändern Sie Ihr Passwort, um wieder Zugang
        zum System zu erhalten.
      </b-alert>
      <b-form @submit="onSubmit">
        <b-form-group label-cols="4" label-cols-lg="2" label-size="lg"
                      label="Altes Passwort"
                      label-for="input-change-pw-old">
          <b-form-input type="password" v-model="old_pw" id="input-change-pw-old"
                        size="lg"></b-form-input>
        </b-form-group>
        <b-form-group label-cols="4" label-cols-lg="2" label-size="lg" label="Neues Passwort"
                      label-for="input-new-pw">
          <b-form-input type="password" v-model="new_pw" id="input-new-pw" size="lg"></b-form-input>
        </b-form-group>
        <b-form-group label-cols="4" label-cols-lg="2" label-size="lg" label="Neues Passwort wiederholen"
                      label-for="input-new-pw-2">
          <b-form-input v-model="new_pw2" :state="validation()" type="password" id="input-new-pw-2"
                        size="lg"></b-form-input>
          <b-form-invalid-feedback :state="validation()">
            Passwörter stimmen nicht überein, unterscheiden sich nicht vom alten Passwort, sind leer oder enthalten
            nicht mindestens 5 Zeichen.
          </b-form-invalid-feedback>
        </b-form-group>
        <b-alert v-if="err" show variant="danger">
          {{ err }}
        </b-alert>
        <b-alert v-if="success" show variant="success">
          {{ success }}
        </b-alert>
        <b-button type="submit" variant="primary" size="lg">Ändern</b-button>
      </b-form>
    </b-jumbotron>
  </div>
</template>

<script>
import UserService from '@/services/user.service'

export default {
  name: 'pwchange',
  title: 'Passwort ändern',
  data () {
    return {
      old_pw: null,
      new_pw: null,
      new_pw2: null,
      err: null,
      success: null
    }
  },
  methods: {
    onSubmit: function (evt) {
      this.err = null
      evt.preventDefault()
      if (!this.validation()) {
        return
      }
      let self = this
      UserService.changePw(this.old_pw, this.new_pw, this.new_pw2).then((data) => {
        this.success = 'Passwort erfolgreich geändert.'
        UserService.getMe().then((user) => {
          self.$store.commit('login', user.data)
          if ('admin' in this.$store.state.user) {
            self.$router.push('/')
          }
        })
      }).catch((data) => {
        this.success = null
        window.console.debug(data)
        switch (data.response.data.status) {
          case 'invalid_old_password':
            this.err = 'Altes Passwort falsch.'
            break
          default:
            alert(data.respsonse.data.status)
        }
      })
    },
    validation () {
      return this.old_pw !== this.new_pw && this.new_pw != null && this.new_pw.trim().length >= 5 && this.new_pw === this.new_pw2
    }
  }
}
</script>
