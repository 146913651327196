<template>
  <div id="statementUpload">
    <b-jumbotron header="[Admin] Kontoauszugsupload" lead="CAMT-CSV">
      <b-form @submit="onSubmit">
        <b-textarea v-model="holvi"></b-textarea>
        <input type="file" @change="processFile($event)">
        <b-button type="submit" variant="primary" size="lg">Upload</b-button>
      </b-form>
      <h2 v-if="import_result">Importergebnis</h2>
      <b-table striped hover v-if="import_result" :items="import_result" :fields="results_fields">
      </b-table>
    </b-jumbotron>
  </div>
</template>

<script>
import AdminService from '@/services/admin.service'

export default {
  name: 'BankStatementUpload',
  title: 'Kontoauszugsupload',
  data: function () {
    return {
      holvi: null,
      file: null,
      import_result: null,
      results_fields: [
        {
          'key': 'Beguenstigter/Zahlungspflichtiger'
        },
        {
          'key': 'Betrag'
        },
        {
          'key': 'Buchungstag'
        },
        {
          'key': 'Kontonummer/IBAN'
        },
        {
          'key': 'Verwendungszweck'
        },
        {
          'key': 'result',
          'label': 'Throat-Buchungstyp'
        },
        {
          'key': 'inserted',
          'label': 'Neu?'
        }
      ]
    }
  },
  methods: {
    processFile (event) {
      this.file = event.target.files[0]
    },
    onSubmit: function (evt) {
      evt.preventDefault()
      let formData = new FormData()
      if (this.file !== null) {
        formData.append('file', this.file)
      } else {
        formData.append('holvi_json', this.holvi)
      }
      let self = this
      AdminService.uploadStatement(formData).then(function (data) {
        window.console.debug('Upload success')
        self.import_result = data.data
      }).catch(function (e) {
        alert('Upload failed:' + e)
      })
    }
  }
}
</script>

<style scoped>
</style>
