import Axios from 'axios'

const LOGIN_RESOURCE = '/api/login'
const LOGOUT_RESOURCE = '/api/logout'

export default {
  login (email, password) {
    return Axios.post(LOGIN_RESOURCE, { 'email': email, 'password': password })
  },
  logout () {
    return Axios.get(LOGOUT_RESOURCE)
  }
}
