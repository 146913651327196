export default {
  dict_by_value_of_array (arr, val) {
    const res = {}
    for (let i = 0; i < arr.length; i++) {
      res[arr[i][val]] = arr[i]
    }
    return res
  },
  dict_of_lists_by_value_of_array (arr, val) {
    const res = {}
    for (let i = 0; i < arr.length; i++) {
      if (!(arr[i][val] in res)) {
        res[arr[i][val]] = []
      }
      res[arr[i][val]].push(arr[i])
    }
    return res
  }
}
