import Axios from 'axios'

const BASE_RESOURCE_NAME = '/api/admin'
const PAYMENTS = `${BASE_RESOURCE_NAME}/payments`
const PAYMENTS_COVERAGE = `${PAYMENTS}/coverage`
const STATEMENT_UPLOAD = `${BASE_RESOURCE_NAME}/statement_upload`
const BROADCAST = `${BASE_RESOURCE_NAME}/broadcast`
const SUDO = `${BASE_RESOURCE_NAME}/sudo`
const ADMIN_INVOICES = `${BASE_RESOURCE_NAME}/invoices/unpaid`

export default {
  getCoverage (year) {
    return Axios.get(PAYMENTS_COVERAGE + '/' + year)
  },
  getUnpaidInvoices () {
    return Axios.get(ADMIN_INVOICES)
  },
  uploadStatement (formData) {
    return Axios.post(STATEMENT_UPLOAD, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },
  sendBroadcast (subjectTemplate, bodyTemplate, draft) {
    return Axios.post(BROADCAST, { 'subject_template': subjectTemplate, 'body_template': bodyTemplate, 'draft': draft })
  },
  sudo (ref) {
    return Axios.post(SUDO, { 'ref': ref })
  }

}
