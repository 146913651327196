import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'
import Mail from './views/Mail.vue'
import Login from './views/Login.vue'
import PWChange from './views/PWChange.vue'
import AccountUpdate from './views/AccountUpdate.vue'
import BankStatementUpload from './views/BankStatementUpload'
import Broadcast from './views/SendBroadcast'
import AVContract from '@/views/AVContract'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/mail',
      name: 'mail',
      component: Mail
    },
    {
      path: '/login',
      name: 'login',
      component: Login
    },
    {
      path: '/account/password',
      name: 'pwchange',
      component: PWChange
    },
    {
      path: '/account',
      name: 'accountUpdate',
      component: AccountUpdate
    },
    {
      path: '/av',
      name: 'av_contract',
      component: AVContract
    },
    {
      path: '/admin/statement_upload',
      name: 'statementUpload',
      component: BankStatementUpload
    },
    {
      path: '/admin/broadcast',
      name: 'broadcast',
      component: Broadcast
    }
  ]
})
