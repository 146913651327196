<template>
  <div class="login">
    <b-jumbotron header="Hallo!" lead="Bitte melden Sie sich mit Ihrer E-Mail-Adresse und Ihrem Passwort an.">
      <b-form @submit="onSubmit">
        <b-form-group label-cols="4" label-cols-lg="2" label-size="lg" label="E-Mail-Adresse"
                      label-for="input-login-email">
          <b-form-input v-model="email" id="input-login-email" size="lg"></b-form-input>
        </b-form-group>
        <b-form-group label-cols="4" label-cols-lg="2" label-size="lg" label="Passwort"
                      label-for="input-login-password">
          <b-form-input v-model="password" type="password" id="input-login-password" size="lg"></b-form-input>
        </b-form-group>
        <b-button type="submit" variant="primary" size="lg">Login</b-button>
      </b-form>
    </b-jumbotron>
  </div>
</template>

<script>
import LoginService from '@/services/login.service'
import UserService from '@/services/user.service'

export default {
  name: 'login',
  title: 'Login',
  data () {
    return {
      email: null,
      password: null
    }
  },
  methods: {
    onSubmit: function (evt) {
      evt.preventDefault()
      LoginService.login(this.email, this.password).then((data) => {
        switch (data.data.status) {
          case 'success':
            UserService.getMe().then((user) => {
              this.$store.commit('login', user.data)
              if ('domain' in user.data) {
                this.$router.push('/account/password')
              } else {
                this.$router.push('/')
              }
            })
            break
          case 'pw_change_required':
            this.$router.push('/account/password')
            this.$store.commit('pw_must_change')
            break
          default:
            alert(data.data.staus)
            break
        }
      })
    }
  }
}
</script>
