<template>
  <div class="login">
    <b-jumbotron header="Nutzerdaten">
      <b-form @submit="onSubmit" v-if="$store.state.user">
        <b-form-group label-cols="4" label-cols-lg="2" label-size="lg"
                      label="Name"
                      label-for="user_name">
          <b-form-input type="text" v-model="$store.state.user.name" disabled id="user_name"
                        size="lg"></b-form-input>
        </b-form-group>
        <b-form-group label-cols="4" label-cols-lg="2" label-size="lg"
                      label="E-Mail"
                      label-for="user_email">
          <b-form-input type="text" v-model="$store.state.user.mail" disabled id="user_email"
                        size="lg"></b-form-input>
        </b-form-group>
        <b-form-group label-cols="4" label-cols-lg="2" label-size="lg"
                      label="Organisation/Firma"
                      label-for="user_organisation">
          <b-form-input type="text" v-model="$store.state.user.organisation" disabled id="user_organisation"
                        size="lg"></b-form-input>
        </b-form-group>
        <b-form-group label-cols="4" label-cols-lg="2" label-size="lg"
                      label="Straße und Hausnummer"
                      label-for="user_street">
          <b-form-input type="text" v-model="$store.state.user.street_and_number" disabled id="user_street"
                        size="lg"></b-form-input>
        </b-form-group>
        <b-form-group label-cols="4" label-cols-lg="2" label-size="lg"
                      label="Postleitzahl"
                      label-for="user_postal_code">
          <b-form-input type="text" v-model="$store.state.user.postal_code" disabled id="user_postal_code"
                        size="lg"></b-form-input>
        </b-form-group>
        <b-form-group label-cols="4" label-cols-lg="2" label-size="lg"
                      label="Stadt"
                      label-for="user_city">
          <b-form-input type="text" v-model="$store.state.user.city" disabled id="user_city"
                        size="lg"></b-form-input>
        </b-form-group>
        <b-form-group label-cols="4" label-cols-lg="2" label-size="lg"
                      label="Land"
                      label-for="user_country">
          <b-form-input type="text" v-model="$store.state.user.country[0]" disabled id="user_country"
                        size="lg"></b-form-input>
        </b-form-group>
        <b-form-group label-cols="4" label-cols-lg="2" label-size="lg"
                      label="Telefon"
                      label-for="user_phone">
          <b-form-input type="text" v-model="$store.state.user.phone" disabled id="user_phone"
                        size="lg"></b-form-input>
        </b-form-group>
        <b-alert v-if="err" show variant="danger">
          {{err}}
        </b-alert>
        <b-button type="submit" variant="primary" size="lg">Ändern</b-button>
      </b-form>
    </b-jumbotron>
  </div>
</template>

<script>

export default {
  name: 'accountUpdate',
  title: 'Account',
  methods: {
    onSubmit: function (evt) {
      this.err = null
      evt.preventDefault()
    }
  }
}
</script>
