<template>
  <div id="sendBroadcast">
    <h1>[Admin] Broadcast</h1>
      <b-form @submit="onSubmit">
        <b-form-group label-cols="4" label-cols-lg="2" label-size="lg"
                      label="Betreff"
                      label-for="subject">
          <b-form-input id="subject" type="text" v-model="subjectTemplate">
          </b-form-input>
        </b-form-group>
        <b-form-group label-cols="4" label-cols-lg="2" label-size="lg"
                      label="Body"
                      label-for="body">
          <b-textarea id="body" v-model="bodyTemplate" rows="30" cols="50"></b-textarea>
        </b-form-group>
        <b-button type="submit" variant="secondary" size="lg">Draft</b-button>
        <b-button @click="send(false)" variant="primary" size="lg">Send</b-button>
      </b-form>
  </div>
</template>

<script>
import AdminService from '@/services/admin.service'

export default {
  name: 'SendBroadcast',
  title: 'Broadcast',
  data: function () {
    return {
      bodyTemplate: '',
      subjectTemplate: ''
    }
  },
  methods: {
    processFile (event) {
      this.file = event.target.files[0]
    },
    send (draft) {
      AdminService.sendBroadcast(this.subjectTemplate, this.bodyTemplate, draft).then().catch(function (e) {
        alert(e)
      })
    },
    onSubmit: function (evt) {
      evt.preventDefault()
      this.send(true)
    }
  }
}
</script>

<style scoped>
</style>
