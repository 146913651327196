<template>
  <div id="app">
    <div>
      <b-navbar toggleable="sm" type="light" variant="light">
        <b-navbar-toggle target="nav-text-collapse"></b-navbar-toggle>

        <b-navbar-brand :to="(!$store.state.user || 'admin' in $store.state.user)?'/':'/account/password'">Yellowant Hosting Dashboard
        </b-navbar-brand>

        <b-collapse id="nav-text-collapse" is-nav>
          <b-navbar-nav>
            <b-nav-item :active="$router.currentRoute.path == '/mail'" to="/mail"
                        v-if="$store.state.user && 'name' in $store.state.user">E-Mail-Verwaltung
            </b-nav-item>
          </b-navbar-nav>
          <b-navbar-nav>
            <b-nav-item :active="$router.currentRoute.path == '/av'" to="/av"
                        v-if="$store.state.user && 'name' in $store.state.user">AV-Vertrag
            </b-nav-item>
          </b-navbar-nav>
          <b-navbar-nav class="ml-auto">
            <b-nav-item to="/account" v-if="$store.state.user && 'name' in $store.state.user">
              {{ $store.state.user.name }}
            </b-nav-item>
            <b-nav-item to="/account/password" :active="$router.currentRoute.path == '/account/password'"
                        v-if="$store.state.user">Passwort ändern
            </b-nav-item>
            <b-nav-item @click="logout" v-if="$store.state.user">Abmelden</b-nav-item>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
    </div>
    <b-container>
      <router-view></router-view>
      <footer>
        <hr/>
        "I'll sell it for less, and that's <a target="_blank" href="https://github.com/janisstreib/cut_me_own_throat">cutting
        me own throat</a>."
      </footer>
    </b-container>
  </div>
</template>
<style>
@import 'css/throat.css';
</style>
<script>

import LoginService from '@/services/login.service'

export default {
  methods: {
    logout: function () {
      LoginService.logout().then((data) => {
        this.$store.commit('logout')
        this.$router.push('/login')
      })
    }
  }
}
</script>
