<template>
  <div class="home" v-if="$store.state.user">
    <h1>Auftragsdatenverarbeitungsvertrag</h1>
    <p>Wir können Ihnen einen Auftragsdatenverabeitungsvertrag direkt zum Abschluss auf dieser Seite anbieten.</p>
    <b-card title="Vertrag" sub-title="">
      <template v-if="contract === null">
        Kein Vertrag abgeschlossen.<br/>
        <b-link target="_blank" href="/api/me/av_contract/preview">Demo-Vertrag</b-link>
        <br/>
        <b-checkbox v-model="accept_checkbox" :unchecked-value="false">Ich stimme dem oben aufgeführten Vertrag zu.
        </b-checkbox>
        <br/>
        <b-button @click="sign" :disabled="!accept_checkbox">Vertrag abschließen</b-button>
      </template>
      <template v-else>
        Vertrag abgeschlossen.<br/>
        Abgeschlossen: {{ contract.accepted }}<br/>
        SHA512-Summe: {{ contract.shasum }}<br/>
        <b-link target="_blank" href="/api/me/av_contract/download">Download</b-link>
      </template>
    </b-card>
  </div>
</template>
<script>
import UserService from '@/services/user.service'

export default {
  name: 'av_contract',
  title: 'AV-Vertrag',
  data () {
    return {
      contract: null,
      accept_checkbox: false
    }
  },
  methods: {
    async sign () {
      if (!this.accept_checkbox) {
        return
      }
      await UserService.signAVContract()
      let res = await UserService.getAVContract()
      this.contract = res.data
    }
  },
  async created () {
    let res = await UserService.getAVContract()
    this.contract = res.data
  }
}
</script>
