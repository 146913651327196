<template>
  <div class="home">
    <h1 v-if="$store.state.user">Willkommen, {{ $store.state.user.name }}!</h1>
    <b-card title="Aktive Leistungen" sub-title="Aktuell abgerechnete Leistungen">
      <b-table striped hover :busy="!positions" :items="positions" :fields="position_fields">
        <template v-slot:cell(frist)="data">
          <span v-if="data.item.interval != 'once'">Eine Woche</span>
          <span v-else>N/A</span>
        </template>
      </b-table>
    </b-card>
    <b-card title="Letze Rechnungen" sub-title="">
      <b-table striped hover :busy="!invoices" :items="invoices" :fields="invoice_fields">
        <template v-slot:cell(payment_method)>
          Überweisung
        </template>
        <template v-slot:cell(paid)="data">
          <span v-if="data.value === 0">
          <b-badge variant="danger" v-if="Date.parse(data.item.due) < Date.now()">Überfällig</b-badge>
          <b-badge variant="warning" v-else>Ausstehend</b-badge>
          </span>
          <b-badge variant="success" v-else>Bezahlt</b-badge>
        </template>
        <template v-slot:cell(actions)="row">
          <b-link class="invoice_action" v-b-tooltip.hover title="Rechnung" :href="`/api/invoice/${row.item.number}/pdf`">
            <font-awesome-icon icon="file-pdf" size="lg"/>
          </b-link>
          <b-link class="invoice_action" v-if="row.item.due2" v-b-tooltip.hover title="Zahlungserinnerung" :href="`/api/invoice_reminder/${row.item.number}/pdf/1`">
            <font-awesome-icon icon="file-pdf" size="lg"/>
            <font-awesome-icon icon="exclamation" size="lg"/>
          </b-link>
          <b-link class="invoice_action" v-if="row.item.due3" v-b-tooltip.hover title="2. Mahnung" :href="`/api/invoice_reminder/${row.item.number}/pdf/2`">
            <font-awesome-icon icon="file-pdf" size="lg"/>
            <font-awesome-icon icon="exclamation-triangle" size="lg"/>
          </b-link>
          <b-link class="invoice_action" v-b-tooltip.hover title='EPC-QR-Code ("GiroCode") / BezahlCode'
                  @click="show_epc_qr_modal(row.item, row.index, $event.target)">
            <font-awesome-icon icon="qrcode" size="lg"/>
          </b-link>
        </template>
      </b-table>
      <b-modal id="modal-epc-qr" title='EPC-QR-Code ("GiroCode") / BezahlCode' ok-only>
        <p class="text-center">
          EPC-QR/GiroCode
          <b-img center :src="`/api/invoice/${epc_qr_item.content.number}/epc-qr`"></b-img>
        </p>
        <p class="text-center">
          Bezahlcode
          <b-img center :src="`/api/invoice/${epc_qr_item.content.number}/bezahlcode`"></b-img>
        </p>
      </b-modal>
    </b-card>
    <b-card title="[Admin] Ausstehende Rechnungen" sub-title="" v-if="unpaid_invoices">
      <b-table striped hover :busy="!unpaid_invoices" :items="unpaid_invoices" :fields="admin_invoice_fields">
        <template v-slot:cell(payment_method)>
          Überweisung
        </template>
        <template v-slot:cell(paid)="data">
          <span v-if="data.value === 0">
          <b-badge variant="danger" v-if="Date.parse(data.item.due) < Date.now()">Überfällig</b-badge>
          <b-badge variant="warning" v-else>Ausstehend</b-badge>
          </span>
          <b-badge variant="success" v-else>Bezahlt</b-badge>
        </template>
        <template v-slot:cell(user)="row">
          <a :href="'mailto:'+row.item.user.mail">{{ row.item.user.ref_name }}</a>
        </template>
        <template v-slot:cell(actions)="row">
          <b-link class="invoice_action" v-b-tooltip.hover title="Rechnung" :href="`/api/invoice/${row.item.number}/pdf`">
            <font-awesome-icon icon="file-pdf" size="lg"/>
          </b-link>
          <b-link class="invoice_action" v-if="row.item.due2" v-b-tooltip.hover title="Zahlungserinnerung" :href="`/api/invoice_reminder/${row.item.number}/pdf/1`">
            <font-awesome-icon icon="file-pdf" size="lg"/>
            <font-awesome-icon icon="exclamation" size="lg"/>
          </b-link>
          <b-link class="invoice_action" v-if="row.item.due3" v-b-tooltip.hover title="2. Mahnung" :href="`/api/invoice_reminder/${row.item.number}/pdf/2`">
            <font-awesome-icon icon="file-pdf" size="lg"/>
            <font-awesome-icon icon="exclamation-triangle" size="lg"/>
          </b-link>
          <b-link class="invoice_action" v-b-tooltip.hover title='EPC-QR-Code ("GiroCode") / BezahlCode'
                  @click="show_epc_qr_modal(row.item, row.index, $event.target)">
            <font-awesome-icon icon="qrcode" size="lg"/>
          </b-link>
        </template>
      </b-table>
      Sudo: <b-input v-model="sudo"></b-input><b-button @click="do_sudo()">GO</b-button>
    </b-card>
    <b-card :title="'[Admin] Umsatz ' + selected_year" v-if="sales">
      <b-form-select v-model="selected_year" @input="refreshCoverage()">
        <b-select-option v-bind:key="i" v-for="i in years" :value="current_year - i">
          {{ current_year - i }}
        </b-select-option>
      </b-form-select>
      <div style="margin-top: 20px"></div>
      <ccv-line-chart v-if="sales" :data="sales"
                      :options="{ containerResizable: true, tooltip:{formatter: (val) => val/100+'€'},scales: { y: {  yMaxAdjuster: yMaxValue => yMaxValue * 1.1, formatter: (val) => val/100+'€'}}}"></ccv-line-chart>
      <h5>Jahresbilanz</h5>
      <b-table-simple>
        <b-tr>
          <b-th>Ausgaben gesamt</b-th>
          <b-td>{{
              format_eur(expenses_raw.reduce((accumulator, currentValue) => accumulator + currentValue, 0))
            }}
          </b-td>
        </b-tr>
        <b-tr>
          <b-th>Umsatz gesamt</b-th>
          <b-td>{{ format_eur(sales_raw.reduce((accumulator, currentValue) => accumulator + currentValue, 0)) }}</b-td>
        </b-tr>
        <b-tr>
          <b-th>Gewinn gesamt</b-th>
          <b-td>{{ format_eur(profit_raw.reduce((accumulator, currentValue) => accumulator + currentValue, 0)) }}</b-td>
        </b-tr>
      </b-table-simple>
    </b-card>
  </div>
</template>
<style>
@import '~@carbon/charts/style.css';
</style>
<script>
import UserService from '@/services/user.service'
import AdminService from '@/services/admin.service'

export default {
  name: 'home',
  title: 'Home',
  data () {
    let invoiceFields = [
      {
        key: 'number',
        label: 'Rechnungsnummer'
      },
      {
        key: 'date',
        label: 'Rechnungsdatum'
      },
      {
        key: 'amount',
        label: 'Betrag',
        formatter: this.format_eur
      },
      {
        key: 'due',
        label: 'Zahlbar bis'
      },
      {
        key: 'payment_method',
        label: 'Zahlmethode'
      },
      {
        key: 'paid',
        label: 'Status'
      },
      {
        key: 'actions',
        label: 'Aktionen'
      }
    ]
    let adminInvoiceFields = [
      {
        key: 'user',
        label: 'Kunde'
      },
      {
        key: 'number',
        label: 'Rechnungsnummer'
      },
      {
        key: 'date',
        label: 'Rechnungsdatum'
      },
      {
        key: 'amount',
        label: 'Betrag',
        formatter: this.format_eur
      },
      {
        key: 'due',
        label: 'Zahlbar bis'
      },
      {
        key: 'due2',
        label: 'Erweiterte Frist'
      },
      {
        key: 'payment_method',
        label: 'Zahlmethode'
      },
      {
        key: 'paid',
        label: 'Status'
      },
      {
        key: 'actions',
        label: 'Aktionen'
      }
    ]
    const currentYear = new Date().getFullYear()
    const years = [...Array(currentYear - 2020 + 1).keys()]
    return {
      years: years,
      current_year: currentYear,
      selected_year: currentYear,
      epc_qr_item: {
        id: 'modal-epc-qr',
        title: '',
        content: ''
      },
      sudo: null,
      sales_raw: null,
      expenses_raw: null,
      profit_raw: null,
      positions: null,
      unpaid_invoices: null,
      invoices: null,
      sales: null,
      invoice_fields: invoiceFields,
      admin_invoice_fields: adminInvoiceFields,
      position_fields: [
        {
          key: 'name'
        },
        {
          key: 'value',
          label: 'Preis',
          formatter: this.format_eur
        },
        {
          key: 'start',
          label: 'Gebucht seit'
        },
        {
          key: 'interval',
          label: 'Abrechnungsinterval',
          formatter: this.format_interval
        },
        {
          key: 'frist',
          label: 'Kündigungsfrist'
        }
      ]
    }
  },
  methods: {
    do_sudo: async function () {
      await AdminService.sudo(this.sudo)
    },
    format_eur: function (val) {
      return ((val / 100.0).toFixed(2) + ' €').replace('.', ',')
    },
    format_interval: function (val) {
      switch (val) {
        case 'monthly':
          return 'monatlich'
        case 'yearly':
          return 'jährlich'
        default:
          return val
      }
    },
    show_epc_qr_modal: function (item, index, button) {
      this.epc_qr_item.title = `Row index: ${index}`
      this.epc_qr_item.content = item
      this.$root.$emit('bv::show::modal', this.epc_qr_item.id, button)
    },
    refreshCoverage () {
      this.sales = null
      AdminService.getCoverage(this.selected_year).then((res) => {
        this.sales_raw = res.data.map((e) => e.sales)
        this.expenses_raw = res.data.map((e) => e.expenses)
        this.profit_raw = res.data.map((e) => e.profit)
        this.sales = {
          labels: [...Array(12).keys()].map((n) => ++n),
          datasets: [
            {
              label: 'Umsatz',
              data: this.sales_raw
            },
            {
              label: 'Ausgaben',
              data: this.expenses_raw
            },
            {
              label: 'Gewinnn',
              data: this.profit_raw
            }

          ]
        }
      })
    }
  },
  created () {
    UserService.getPositions().then((res) => {
      this.positions = res.data
    })
    UserService.getAllInvoices().then((res) => {
      this.invoices = res.data
    })
    AdminService.getUnpaidInvoices().then((res) => {
      this.unpaid_invoices = res.data
    })
    this.refreshCoverage()
  }
}
</script>
