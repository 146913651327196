import Vue from 'vue'
import App from './App.vue'
import router from './router'
import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import Axios from 'axios'
import Vuex from 'vuex'

import UserService from '@/services/user.service'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faQrcode, faFilePdf, faExclamation, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import chartsVue from '@carbon/charts-vue'

import titleMixin from './mixins/titleMixin'

Vue.use(chartsVue)
Vue.use(Vuex)
Vue.mixin(titleMixin)
library.add(faQrcode)
library.add(faFilePdf)
library.add(faExclamation)
library.add(faExclamationTriangle)

Vue.component('font-awesome-icon', FontAwesomeIcon)
const store = new Vuex.Store({
  state: {
    user: null,
    pw_must_change: false
  },
  mutations: {
    login (state, user) {
      state.user = user
      state.pw_must_change = user.pw_must_change
    },
    logout (state) {
      state.user = null
    },
    pw_must_change (state) {
      state.pw_must_change = true
    }
  },
  getters: {
    getLoginState (state) {
      return state.user != null
    }
  }
})

Vue.use(BootstrapVue)
Vue.config.productionTip = false
Vue.config.errorHandler = function (err, vm, info) {
  // handle error
  // `info` is a Vue-specific error info, e.g. which lifecycle hook
  // the error was found in. Only available in 2.2.0+
  alert(err, info)
  window.console.log(err)
}

window.onerror = function (message, source, lineno, colno, error) {
  window.console.log(error)
}
Axios.defaults.baseURL = '/'
new Vue({
  router,
  store,
  render: h => h(App),
  created () {
    window.console.debug('Vue created')
    UserService.getMe().then((data) => {
      this.$store.commit('login', data.data)
    }).catch((data) => {
      this.$store.commit('logout')
    })
    var self = this
    Axios.interceptors.response.use(
      function (response) {
        return response
      },
      function (error) {
        // handle error
        if (error.response) {
          var stat = error.response.status
          switch (stat) {
            case 403:
              if (error.response.data && error.response.data.status === 'pw_change_required') {
                self.$store.commit('pw_must_change')
                self.$router.push('/account/password')
              } else {
                self.$store.commit('logout')
                router.push('/login')
              }
              break
            default:
              break
          }
        } else {
          alert(error)
        }
        return Promise.reject(error)
      })
  }
}).$mount('#app')
