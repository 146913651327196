import Axios from 'axios'

const BASE_RESOURCE_NAME = '/api/me'
const POSITIONS = `${BASE_RESOURCE_NAME}/positions`
const PASSWORD = `${BASE_RESOURCE_NAME}/password`
const INVOICES = `${BASE_RESOURCE_NAME}/invoices`
const DOMAINS = `${BASE_RESOURCE_NAME}/domains`

export default {
  getMe () {
    return Axios.get(BASE_RESOURCE_NAME)
  },
  getPositions (email, password) {
    return Axios.get(POSITIONS)
  },
  getAllInvoices () {
    return Axios.get(INVOICES)
  },
  changePw (old, new1, new2) {
    return Axios.post(PASSWORD, { 'old_pw': old, 'new_pw': new1, 'new_pw2': new2 })
  },
  getDomains () {
    return Axios.get(DOMAINS)
  },
  getMailboxes (domain) {
    return Axios.get(`${BASE_RESOURCE_NAME}/domains/${domain}/mail/boxes`)
  },
  getMailForwards (domain) {
    return Axios.get(`${BASE_RESOURCE_NAME}/domains/${domain}/mail/forwards`)
  },
  deleteMailForward (domain, localPart) {
    return Axios.post(`${BASE_RESOURCE_NAME}/domains/${domain}/mail/forwards/delete`, { local_part: localPart })
  },
  deleteMailbox (domain, username) {
    return Axios.post(`${BASE_RESOURCE_NAME}/domains/${domain}/mail/boxes/delete`, { username: username })
  },
  createMailbox (domain, username, password, quota) {
    return Axios.post(`${BASE_RESOURCE_NAME}/domains/${domain}/mail/boxes/create`, {
      username: username,
      password: password,
      quota: quota
    })
  },
  updateMailbox (domain, username, password, quota) {
    return Axios.post(`${BASE_RESOURCE_NAME}/domains/${domain}/mail/boxes/update`, {
      username: username,
      password: password,
      quota: quota
    })
  },
  updateForward (id, domain, localPart, target, oder) {
    return Axios.post(`${BASE_RESOURCE_NAME}/domains/${domain}/mail/forwards/update`, {
      id: id,
      local_part: localPart,
      target: target,
      order: oder
    })
  },
  createForward (domain, localPart, target, order) {
    return Axios.post(`${BASE_RESOURCE_NAME}/domains/${domain}/mail/forwards/create`, {
      local_part: localPart,
      target: target,
      order: order
    })
  },
  getAVContract () {
    return Axios.get(`${BASE_RESOURCE_NAME}/av_contract`)
  },
  signAVContract () {
    return Axios.post(`${BASE_RESOURCE_NAME}/av_contract/sign`)
  }
}
