<template>
  <div class="home" v-if="$store.state.user">
    <h1>E-Mail-Verwaltung</h1>
    <b-card title="Postfächer" sub-title="">
      <b-button variant="outline-primary" v-b-toggle.maildoc>Anleitung zur Einrichtung der Mailbox</b-button>
      <b-collapse id="maildoc" class="mt-2">
        <ul>
          <li>IMAP
            <ul>
              <li>Variante 1:
                <ul>
                  <li>IMAP Serverdaten: <code>imap.yellowant.de</code> Port <code>143</code></li>
                  <li>STARTTLS benutzen</li>
                </ul>
              </li>
              <li>Variante 2:
                <ul>
                  <li>IMAP Serverdaten: <code>imap.yellowant.de</code> Port <code>993</code></li>
                  <li>SSL benutzen</li>
                </ul>
              </li>
              <li>Benutzername: Die <i>vollständige</i> E-Mail-Adresse des Postfaches</li>
            </ul>
          </li>
          <li>SMTP
            <ul>
              <li>Variante 1:
                <ul>
                  <li>Serverdaten: <code>smtp.yellowant.de</code> Port <code>587</code></li>
                </ul>
              </li>
              <li>Variante 2 (Outlook):
                <ul>
                  <li>Serverdaten: <code>smtp.yellowant.de</code> Port <code>465</code></li>
                  <li>SSL benutzen</li>
                </ul>
              </li>
              <li>Benutzername: Die <i>vollständige</i> E-Mail-Adresse des Postfaches</li>
            </ul>
          </li>
        </ul>
      </b-collapse>
      <p>
        Die Nutzer der Postfächer können ihr Passwort unter <a href="https://customer.yellowant.de">https://customer.yellowant.de</a>
        ändern.
        Ein Webmailer steht unter <a href="https://mail.yellowant.de">https://mail.yellowant.de</a> zur
        Verfügung.
      </p>
      <b-table striped hover :busy="!domains" :items="boxes" :fields="boxesFields">
        <template v-slot:head(actions)>
          <b-button variant="outline-success" @click="createBoxModal"
                    :disabled="domains === null || domains.length == 0">
            Neues Postfach anlegen
          </b-button>
        </template>
        <template v-slot:cell(actions)="data">
          <b-button-group>
            <b-button variant="outline-primary"
                      @click="updateBoxModal(domains_by_id[data.item.domain.id].domain, data.item.username, data.item.quota)">
              Passwort und Quota
              ändern
            </b-button>
            <b-button variant="outline-danger"
                      @click="deleteBox(domains_by_id[data.item.domain.id].domain, data.item.username)">Löschen
            </b-button>
          </b-button-group>
        </template>
      </b-table>
    </b-card>
    <b-card title="Weiterleitungen" sub-title="">
      <b-table striped hover :busy="!domains" :items="forwards" :fields="forwardsFields">
        <template v-slot:head(actions)>
          <b-button variant="outline-success" @click="createForwardModal"
                    :disabled="domains === null || domains.length == 0">Neue Weiterleitung hinzufügen
          </b-button>
        </template>
        <template v-slot:cell(actions)="data">
          <b-button-group>
            <b-button variant="outline-primary" @click="updateForwardModal(data.item)">Bearbeiten</b-button>
            <b-button variant="outline-danger"
                      @click="deleteForward(domains_by_id[data.item.domain.id].domain, data.item.local_part)">Löschen
            </b-button>
          </b-button-group>
        </template>
      </b-table>
    </b-card>
    <b-modal id="update_box" ok-variant="outline-primary" cancel-title="Abbrechen" ok-title="Aktualisieren"
             :title="'Postfach \''+box_create_name+'@'+box_selected_domain+'\' aktualisieren'"
             :ok-disabled="!box_create_pw" @ok="updateBox">
      <b-form-group label="Passwort">
        <b-form-input type="password" v-model="box_create_pw" placeholder="Neues Passwort"/>
      </b-form-group>
      <b-form-group label="Quota"
                    description="Quota in ganzen Gib. -1 = keine Quota. Wird die Quota überschritten, wird das Senden von E-Mails verhindert. Der Empfang ist weiterhin möglich.">
        <b-form-input v-model="box_create_quota" type="number" min="-1"></b-form-input>
      </b-form-group>
    </b-modal>
    <b-modal id="create_box" ok-variant="outline-success" cancel-title="Abbrechen" ok-title="Anlegen"
             title="IMAP-Postfach anlegen" size="lg"
             :ok-disabled="!box_create_name || !box_selected_domain || !box_create_pw"
             @ok="createBox">
      <b-form-group label="Adresse">
        <b-input-group>
          <b-form-input v-model="box_create_name" placeholder="Name" trim></b-form-input>
          <b-input-group-prepend is-text>@</b-input-group-prepend>
          <b-form-select v-model="box_selected_domain">
            <template #first>
              <b-form-select-option :value="null" disabled>-- Domain auswählen --</b-form-select-option>
            </template>
            <b-select-option v-for="d in domains" :value="d.domain" v-bind:key="d.id">{{ d.domain }}</b-select-option>
          </b-form-select>
        </b-input-group>
      </b-form-group>
      <b-form-group label="Passwort">
        <b-form-input v-model="box_create_pw" type="password"></b-form-input>
      </b-form-group>
      <b-form-group label="Quota"
                    description="Quota in ganzen Gib. -1 = keine Quota. Wird die Quota überschritten, wird das Senden von E-Mails verhindert. Der Empfang ist weiterhin möglich.">
        <b-form-input v-model="box_create_quota" type="number" min="-1"></b-form-input>
      </b-form-group>
    </b-modal>
    <b-modal id="create_forward" :ok-variant="forward_modal_is_create?'outline-success':'outline-primary'"
             cancel-title="Abbrechen" :ok-title="forward_modal_is_create?'Anlegen':'Aktualisieren'"
             :title="forward_modal_is_create?'Mail-Weiterleitung anlegen':'Mail-Weiterleitung berabeiten'" size="lg"
             :ok-disabled="!forwrad_create_local_part || !forward_selected_domain || !forwrad_create_target"
             @ok="forward_modal_is_create?createForward():updateForward()">
      <b-form-group label="Adresse">
        <b-input-group>
          <b-form-input v-model="forwrad_create_local_part" placeholder="Name" trim></b-form-input>
          <b-input-group-prepend is-text>@</b-input-group-prepend>
          <b-form-select v-model="forward_selected_domain">
            <template #first>
              <b-form-select-option :value="null" disabled>-- Domain auswählen --</b-form-select-option>
            </template>
            <b-select-option v-for="d in domains" :value="d.domain" v-bind:key="d.id">{{ d.domain }}</b-select-option>
          </b-form-select>
        </b-input-group>
      </b-form-group>
      <b-form-group label="Weiterleitungsziel"
                    description="Vollständie E-Mail des Weiterleitungsziels. Mehrere Einträge komma-separiert möglich.">
        <b-form-input v-model="forwrad_create_target"></b-form-input>
      </b-form-group>
    </b-modal>
  </div>
</template>
<script>
import UserService from '@/services/user.service'
import apiutil from '@/util/apiutil'

export default {
  name: 'mail',
  title: 'E-Mail-Postfächer',
  data () {
    return {
      box_selected_domain: null,
      box_create_pw: null,
      box_create_name: null,
      box_create_quota: -1,
      forward_modal_is_create: true,
      forward_selected_domain: null,
      forwrad_create_target: null,
      forwrad_create_local_part: null,
      forward_update_id: null,
      forward_update_order: null,
      max_forward_order: {},
      domains: null,
      domains_by_id: {},
      domains_by_domain: {},
      boxes: [],
      forwards: [],
      forwardsFields: [
        {
          key: 'effective_address',
          label: 'E-Mail-Adresse'
        },
        {
          key: 'target',
          label: 'Weiterleitungsziel(e)'
        },
        {
          key: 'actions',
          label: 'Aktionen'
        }
      ],
      boxesFields: [
        {
          key: 'effective_address',
          label: 'E-Mail-Adresse',
          sortable: true
        },
        {
          key: 'quota',
          label: 'Quota (Gib)',
          sortable: true
        },
        {
          key: 'actions',
          label: 'Aktionen'
        }
      ]
    }
  },
  methods: {
    async fetch () {
      let doms = await UserService.getDomains()
      this.domains = doms.data
      this.domains_by_id = apiutil.dict_by_value_of_array(this.domains, 'id')
      this.domains_by_domain = apiutil.dict_by_value_of_array(this.domains, 'domain')
      this.boxes = []
      this.forwards = []
      const getProms = []
      for (let d of this.domains) {
        getProms.push(UserService.getMailboxes(d.domain))
        getProms.push(UserService.getMailForwards(d.domain))
        this.max_forward_order[d.id] = 0
      }
      for (let r of (await Promise.all(getProms))) {
        if (r.data.length === 0) {
          continue
        }
        if ('local_part' in r.data[0]) {
          this.forwards.push(...r.data)
        } else {
          this.boxes.push(...r.data)
        }
      }
      for (let f of this.forwards) {
        if (this.max_forward_order[f.domain.id] < f.order) {
          this.max_forward_order[f.domain.id] = f.order
        }
      }
    },
    createBoxModal () {
      this.box_create_name = this.box_create_pw = this.box_selected_domain = null
      this.forward_modal_is_create = true
      this.box_create_quota = -1
      this.$bvModal.show('create_box')
    },
    updateBoxModal (domain, username, quota) {
      this.box_create_name = this.box_create_pw = this.box_selected_domain = null
      this.box_create_name = username
      this.box_selected_domain = domain
      this.box_create_quota = quota
      this.$bvModal.show('update_box')
    },
    async updateBox () {
      await UserService.updateMailbox(this.box_selected_domain, this.box_create_name, this.box_create_pw, this.box_create_quota)
      await this.fetch()
    },
    async createBox () {
      await UserService.createMailbox(this.box_selected_domain, this.box_create_name, this.box_create_pw, this.box_create_quota)
      await this.fetch()
    },
    async deleteBox (domain, username) {
      if (window.confirm('IMAP-Postfach "' + domain + '@' + username + '" wirklich löschen?')) {
        await UserService.deleteMailbox(domain, username)
        await this.fetch()
      }
    },
    async deleteForward (domain, localPart) {
      await UserService.deleteMailForward(domain, localPart)
      await this.fetch()
    },
    createForwardModal () {
      this.forwrad_create_local_part = this.forwrad_create_target = this.forward_selected_domain = null
      this.forward_modal_is_create = true
      this.$bvModal.show('create_forward')
    },
    updateForwardModal (item) {
      this.forwrad_create_local_part = item.local_part
      this.forwrad_create_target = item.target
      this.forward_selected_domain = this.domains_by_id[item.domain.id].domain
      this.forward_modal_is_create = false
      this.forward_update_id = item.id
      this.forward_update_order = item.order
      this.$bvModal.show('create_forward')
    },
    async createForward () {
      await UserService.createForward(this.forward_selected_domain, this.forwrad_create_local_part,
        this.forwrad_create_target.replace(/ /g, ''), this.max_forward_order[this.domains_by_domain[this.forward_selected_domain].id] + 10)
      await this.fetch()
    },
    async updateForward () {
      await UserService.updateForward(this.forward_update_id, this.forward_selected_domain, this.forwrad_create_local_part,
        this.forwrad_create_target.replace(/ /g, ''), this.forward_update_order)
      await this.fetch()
    }
  },
  async created () {
    await this.fetch()
  }
}
</script>
